'use client'

import useCart from 'hooks/useCart'

export default function CartCount() {
  const { data: cartCount, isLoading } = useCart()
  if (isLoading) return <>&nbsp;</>
  if (!cartCount) return '0'

  return cartCount
}
