'use client'

import Logo from './logo'
import CartCount from './cart'
import Link from 'next/link'
import { createPortal } from 'react-dom'
import { useEffect, useState } from 'react'
import { cn, isExternalUrl } from 'lib/utils'
import { mainNav } from 'config/site'
import { Badge } from './badge'

const ChevronRight = () => (
  <div className="block md:hidden">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="14"
      viewBox="0 0 8 14"
      fill="none"
    >
      <path d="M1 1L7 7L1 13" stroke="#7F7F7F" />
    </svg>
  </div>
)

const FooterLink = ({ href, children }) => {
  const isExternal = isExternalUrl(href)
  const styles =
    'text-[#7E7E7E] underline decoration-transparent underline-offset-2 hover:decoration-foreground fmtext-12 md:ftext-9 w-full md:w-auto flex items-center justify-between'
  if (isExternal) {
    return (
      <a href={href} className={styles}>
        {children}
        <ChevronRight />
      </a>
    )
  }
  return (
    <Link href={href} className={styles}>
      {children}
      <ChevronRight />
    </Link>
  )
}

function Cart({ menuOpen = false }: { menuOpen: boolean }) {
  const SHOPIFY_URL = process.env.NEXT_PUBLIC_SHOPIFY_URL

  return (
    <div
      className={cn(
        'w-[6.25vw] h-[7.188vw] md:w-[2.14vw] md:h-[2.35vw] flex justify-center items-center relative text-menuForeground',
        menuOpen && 'text-white',
      )}
    >
      <a
        href={`${SHOPIFY_URL}/cart`}
        className="fmtext-12 md:ftext-10 leading-none block w-full h-full"
      >
        <div className="absolute w-full h-full md:top-[-0.4vw] md:right-[0.3vw]">
          <svg
            width="27"
            height="30"
            viewBox="0 0 27 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="w-full block h-auto"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M27 29C27 29.5523 26.5523 30 26 30L0.999998 30C0.447714 30 -1.86821e-06 29.5523 -1.81993e-06 29L-6.66631e-07 15.8078C-6.52048e-07 15.641 0.0705312 15.4843 0.164686 15.3466L0.421096 14.9716C0.770137 14.4612 0.770137 13.7888 0.421096 13.2784L0.296852 13.0966C-0.0521874 12.5862 -0.0521873 11.9138 0.296852 11.4034L0.421097 11.2216C0.770136 10.7112 0.770136 10.0388 0.421097 9.52835L0.296852 9.34665C-0.052187 8.83618 -0.052187 8.16382 0.296852 7.65335L0.421097 7.47165C0.770136 6.96118 0.770136 6.28882 0.421097 5.77835L0.296852 5.59665C-0.0521886 5.08618 -0.0521885 4.41382 0.296852 3.90335L0.421095 3.72165C0.770136 3.21118 0.770136 2.53882 0.421095 2.02835L0.164685 1.65335C0.0705305 1.51566 5.9653e-07 1.35897 6.11113e-07 1.19216L6.27912e-07 1C6.76194e-07 0.447715 0.447715 -4.13926e-07 1 -3.65644e-07L26 1.81993e-06C26.5523 1.86821e-06 27 0.447718 27 1L27 1.19215C27 1.35896 26.9295 1.51565 26.8353 1.65335L26.5789 2.02835C26.2299 2.53882 26.2299 3.21118 26.5789 3.72165L26.7031 3.90335C27.0522 4.41382 27.0522 5.08618 26.7031 5.59665L26.5789 5.77835C26.2299 6.28882 26.2299 6.96118 26.5789 7.47165L26.7031 7.65335C27.0522 8.16382 27.0522 8.83618 26.7031 9.34665L26.5789 9.52835C26.2299 10.0388 26.2299 10.7112 26.5789 11.2216L26.7031 11.4034C27.0522 11.9138 27.0522 12.5862 26.7031 13.0966L26.5789 13.2784C26.2299 13.7888 26.2299 14.4612 26.5789 14.9716L26.8353 15.3466C26.9295 15.4843 27 15.641 27 15.8079L27 29ZM15.1448 27L11.8552 27C11.5717 27 11.4464 27.3571 11.6678 27.5343L13.3126 28.8501C13.4222 28.9377 13.5778 28.9377 13.6874 28.8501L15.3322 27.5343C15.5536 27.3571 15.4283 27 15.1448 27ZM23 26C23.5523 26 24 25.5523 24 25L24 9C24 8.44772 23.5523 8 23 8L4 8C3.44772 8 3 8.44772 3 9L3 25C3 25.5523 3.44771 26 4 26L23 26Z"
              fill="currentColor"
            />
          </svg>
        </div>
        <span className="fmtext-12 md:ftext-10 w-full h-full flex text-center justify-center items-center relative md:right-[0.3vw] md:top-[-0.3vw]">
          <div className="w-full">
            <CartCount />
          </div>
        </span>
      </a>
    </div>
  )
}

export const MobileNavButton = ({ menuOpen, setMenuOpen }) => {
  return (
    <>
      <button
        className={cn(
          'w-[6.25vw] block transition-all',
          menuOpen && 'rotate-45 text-white',
        )}
        onClick={(evt) => {
          evt.preventDefault()
          setMenuOpen((value) => !value)
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          className="block w-full h-auto"
        >
          <rect x="1" y="9" width="18" height="2" fill="currentColor" />
          <rect x="9" y="1" width="2" height="18" fill="currentColor" />
        </svg>
      </button>
      <Backdrop active={menuOpen} />
    </>
  )
}

const Backdrop = ({ active }) => {
  const [mounted, setMounted] = useState(false)
  useEffect(() => {
    setMounted(true)
  }, [])

  useEffect(() => {
    if (active) {
      document.body.classList.add('fixed')
    } else {
      document.body.classList.remove('fixed')
    }
  }, [active])

  return mounted
    ? createPortal(
        <div
          className={cn(
            'fixed inset-0 bg-black z-[-1] opacity-0',
            active && 'block visible opacity-100 z-[9990]',
          )}
          style={{
            transition: active
              ? 'opacity 0.15s ease-in-out, z-index 0s linear'
              : 'opacity 0.15s ease-in-out, z-index 0s linear 0.15s',
          }}
        />,
        document.body,
      )
    : null
}

const HeaderLink = ({ href, children }) => {
  const isExternal = isExternalUrl(href)
  const styles =
    'fmtext-12 md:ftext-9 flex leading-none text-menuforeground underline decoration-transparent underline-offset-2 hover:decoration-menuForeground'
  if (isExternal) {
    return (
      <a href={href} className={styles}>
        {children}
      </a>
    )
  }
  return (
    <Link href={href} className={styles}>
      {children}
    </Link>
  )
}

const MobileHeaderLink = ({ href, children }) => {
  const isExternal = isExternalUrl(href)
  const styles =
    'flex items-center fmtext-16 leading-[118%] text-[#E5E7E6] py-[4.37vw] border-b-[0.31vw] border-[#1B1B1B]'
  if (isExternal) {
    return (
      <a href={href} className={styles}>
        {children}
      </a>
    )
  }
  return (
    <Link href={href} className={styles}>
      {children}
      {href === '/products' && (
        <Badge
          size="small"
          variant="outline"
          className="ml-[2.5vw]"
          style={{ '--foreground': '#ffffff' }}
        >
          New!
        </Badge>
      )}
    </Link>
  )
}

export default function Header() {
  const [menuOpen, setMenuOpen] = useState(false)

  return (
    <header className={cn('sticky z-[9999] bg-transparent text-menuForeground')}>
      <div
        className={cn(
          'wrapper sticky pt-[5.625vw] pb-[5.625vw] md:pb-[1.22vw] md:pt-[1.83vw] mx-auto grid grid-cols-5 items-center',
          menuOpen && 'z-[9999]',
        )}
      >
        <div className="block md:hidden">
          <MobileNavButton menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
        </div>
        <div className="flex h-full justify-center md:justify-start items-center col-span-3 md:col-span-1">
          <Link href="/" className="inline-flex">
            <Logo
              className={cn(
                'w-[6.25vw] md:w-[2.21vw] z-[9999]',
                menuOpen && 'text-white',
              )}
            />
          </Link>
        </div>
        <nav className={cn('hidden md:block col-span-3')}>
          <ul className="grid grid-cols-3 grid-rows-2 grid-flow-col">
            <li className="flex items-center">
              <HeaderLink href="https://store.analogue.co">Store</HeaderLink>
            </li>
            <li className="flex items-center">
              <HeaderLink href="/products">Products</HeaderLink>
              <Badge size="small" variant="outline" className="ml-[0.4vw] text-menuForeground border-menuForeground">
                New!
              </Badge>
            </li>
            <li className="flex items-center">
              <HeaderLink href="/editions">Editions</HeaderLink>
            </li>
            <li className="flex items-center">
              <HeaderLink href="/developer">Developer</HeaderLink>
            </li>
            <li className="flex items-center">
              <HeaderLink href="/support">Support</HeaderLink>
            </li>
            <li className="flex items-center">
              <HeaderLink href="/announcements">Announcements</HeaderLink>
            </li>
          </ul>
        </nav>
        <div className="text-right ml-auto">
          <Cart menuOpen={menuOpen} />
        </div>
      </div>
      <div
        className={cn(
          menuOpen ? 'block' : 'hidden',
          'overflow-scroll h-[100vh]',
        )}
      >
        <nav className={'wrapper text-white'}>
          <ul>
            {mainNav.map(({ label, href }) => (
              <li key={href}>
                <MobileHeaderLink href={href}>{label}</MobileHeaderLink>
              </li>
            ))}
          </ul>
        </nav>
        <nav className={'wrapper mt-[23vw] pb-[50vw]'}>
          <ul>
            <li className="py-[3vw] border-b-[0.31vw] border-[#1B1B1B]">
              <FooterLink href="/terms-conditions">
                Terms & Conditions
              </FooterLink>
            </li>
            <li className="py-[3vw] border-b-[0.31vw] border-[#1B1B1B]">
              <FooterLink href="/privacy-policy">Privacy</FooterLink>
            </li>
            <li className="py-[3vw] border-b-[0.31vw] border-[#1B1B1B]">
              <FooterLink href="/press">Press</FooterLink>
            </li>
            <li className="py-[3vw] border-b-[0.31vw] border-[#1B1B1B]">
              <FooterLink href="/feed">RSS</FooterLink>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  )
}
