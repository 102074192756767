import useSWR from 'swr'
import Cookies from 'js-cookie'

const fetcher = async () => {
  const data = Cookies.get('cart_count')
  return data || null
}

const useCart = () => {
  const hook = useSWR('cart', fetcher, {
    fallbackData: null,
  })

  return { ...hook }
}

export default useCart
