export type SiteConfig = typeof siteConfig

export const siteConfig = {
  name: 'Analogue',
  description:
    'We make products to celebrate and explore the history of video games with the respect it deserves.',
  social: [
    {
      label: 'Twitter',
      url: 'https://twitter.com/analogue',
    },
    {
      label: 'Instagram',
      url: 'https://instagram.com/analogue.co',
    },
  ],
}

export const mainNav = [
  {
    label: 'Store',
    href: 'https://store.analogue.co',
  },
  {
    label: 'Products',
    href: '/products',
  },
  {
    label: 'Editions',
    href: '/editions',
  },
  {
    label: 'Developer',
    href: '/developer',
  },
  {
    label: 'Support',
    href: '/support',
  },
  {
    label: 'Announcements',
    href: '/announcements',
  }
]