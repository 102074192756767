import { cn } from 'lib/utils'

export default function Logo({ className }: { className?: string }) {
  return (
    <div className={cn('h-auto', className)}>
      <svg
        width="30"
        height="28"
        viewBox="0 0 30 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="w-full h-auto fill-current"
      >
        <path d="M6.8469 15.0172C2.96728 14.7698 -0.231649 18.0026 0.0131812 21.9232C0.214964 25.156 2.81124 27.7797 6.01017 27.9836C9.88978 28.231 13.086 25.001 12.8412 21.0804C12.6421 17.8449 10.0458 15.2211 6.8469 15.0172Z" />
        <path d="M29.1362 18.2513L20.5672 3.24849C18.7915 0.140796 14.8581 -0.92229 11.7829 0.872178C8.70774 2.66665 7.65308 6.63895 9.42877 9.74664L18.0005 24.7495C19.7762 27.8572 23.7069 28.923 26.7821 27.1285C29.86 25.334 30.9119 21.359 29.1362 18.2513Z" />
      </svg>
    </div>
  )
}
