'use client'

import { useState } from 'react'
import { cn } from 'lib/utils'
import sleep from 'helpers/sleep'

export default function FooterNewsletter() {
  const [focused, setFocused] = useState(false)
  const [loading, setLoading] = useState(false)
  const [hasError, setError] = useState(false)
  const [subscribed, setSubscribed] = useState(false)

  const handleSubmit = async (evt) => {
    evt.preventDefault()
    const email = evt.target.email.value
    if (!email) {
      return null
    }
    const source = 'footer'

    try {
      setLoading(true)
      setError(false)
      const body = JSON.stringify({
        email,
        signup_source: source,
      })
      const res = await fetch('/subscribe', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body,
      })
      if (!res.ok) {
        throw new Error(`${res.status}: ${res.statusText}`)
      }
      setSubscribed(true)
      setLoading(false)
      await sleep(1500)
    } catch (err: unknown) {
      const error = err as Error
      console.error(error)
      setError(true)
    } finally {
      setLoading(false)
    }
  }

  let label = 'Subscribe'
  if (loading) {
    label = 'Subscribing'
  }
  if (subscribed) {
    label = 'Subscribed'
  }

  const disabled = loading || subscribed

  return (
    <div>
      <form
        onSubmit={handleSubmit}
        className={cn(
          'border-b flex md:inline-flex mt-6 pb-2 md:w-[90%] justify-between',
          focused && 'border-foreground',
        )}
      >
        <input
          type="email"
          name="email"
          placeholder="E-Mail"
          className="appearance-none w-3/4 md:min-w-[200px] fmtext-13 md:ftext-9 focus:outline-none transition-colors bg-transparent"
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
        />
        <button
          type="submit"
          className={cn('appearance-none transition-colors fmtext-13 md:ftext-9 w-1/4 md:w-auto text-right pr-[0.5vw]', disabled ? 'cursor-not-allowed' : 'cursor-pointer')}
          disabled={disabled}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
        >
          {label}
        </button>
      </form>
      <div className={cn('text-[#F90000] flex fmtext-13 md:ftext-9 mt-f8 md:mt-[f8] gap-[2vw] md:gap-[0.5vw]', hasError ? 'visible' : 'invisible')}>
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-fm16 md:w-f13 h-auto block">
            <path fillRule="evenodd" clipRule="evenodd" d="M7.46875 12.9375C10.4891 12.9375 12.9375 10.4891 12.9375 7.46875C12.9375 4.44844 10.4891 2 7.46875 2C4.44844 2 2 4.44844 2 7.46875C2 10.4891 4.44844 12.9375 7.46875 12.9375ZM7.46875 13.9375C11.0413 13.9375 13.9375 11.0413 13.9375 7.46875C13.9375 3.89616 11.0413 1 7.46875 1C3.89616 1 1 3.89616 1 7.46875C1 11.0413 3.89616 13.9375 7.46875 13.9375Z" fill="#F95A00"/>
            <path d="M8.07433 3.92168L7.82734 8.32337H7.08637L6.83056 3.92168H8.07433ZM6.81292 9.59359C6.81292 9.24075 7.09519 8.94084 7.44803 8.94084C7.8097 8.94084 8.10079 9.24075 8.10079 9.59359C8.10079 9.94643 7.8097 10.2375 7.44803 10.2375C7.09519 10.2375 6.81292 9.94643 6.81292 9.59359Z" fill="#F95A00"/>
          </svg>
        There was an error. Please try again.
      </div>
    </div>
  )
}
