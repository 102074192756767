import { cva, type VariantProps } from 'class-variance-authority'
import { cn } from 'lib/utils'

const badgeVariants = cva(
  'inline-flex items-center rounded-full border transition-colors focus:outline-none',
  {
    variants: {
      variant: {
        default: 'border-transparent bg-background text-foreground',
        outline: 'bg-transparent border-foreground text-foreground',
      },
      size: {
        default:
          'px-[1.5vw] md:px-[0.5vw] py-[0.8vw] md:py-[0.2vw] fmtext-10 md:ftext-10',
        small:
          'px-[1.56vw] md:px-[0.5vw] py-[0.625vw] md:py-[0.2vw] fmtext-10 md:ftext-6 leading-[120%]',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
)

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, size, ...props }: BadgeProps) {
  return (
    <div
      className={cn(badgeVariants({ variant, size }), className)}
      {...props}
    />
  )
}

export { Badge, badgeVariants }
